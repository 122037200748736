<template>
  <main class="further">
    <div class="container">
      <div class="row">
        <div class="col-sm-12">
          <p class="text-center">
            To dive deeper into the topic of feminist issues in Georgia, we invite you to have a look at the resources
            below. This page contains references to books, movies and women artists selected by us, and who will enhance
            your knowledge and understanding of women’s issues in the specific context of Georgia.
          </p>
          <div class="text-center title">
            <h1>Books</h1>
          </div>
          <carousel class="carousel books-carousel" :perPageCustom="[[320, 1.2], [768, 2.5], [1440, 4]]"
                    :scrollPerPage="false">
            <slide v-for="item in books" :key="item.index">
              <img class=""
                   :src="require('@/assets/img/further/books/' + item.pic + '.jpg')"
                   alt="">
              <h3 class="main">{{item.title}}</h3>
              <span class="sub">{{item.author}}</span>
              <p class="book-desc">{{item.description}}</p>
            </slide>
          </carousel>
          <div class="text-center title">
            <h1>Movies</h1>
          </div>
          <carousel class="carousel movies-carousel" :perPageCustom="[[320, 1.2], [768, 2.5], [1440, 4]]"
                    :scrollPerPage="false">
            <slide v-for="item in movies" :key="item.index" class="link-slide">
              <a :href="item.link" target="_blank">
                <img class=""
                     :src="require('@/assets/img/further/movies/' + item.pic + '.jpg')"
                     alt="">
                <h3 class="main">{{item.title}}</h3>
                <span class="sub">{{item.director}}</span>
              </a>
            </slide>
          </carousel>
          <div class="text-center title">
            <h1>Painters</h1>
          </div>
          <carousel class="carousel artists-carousel" :perPageCustom="[[320, 1.2], [768, 2.5], [1440, 3]]"
                    :scrollPerPage="false">
            <slide v-for="item in artists.painters" :key="item.index" class="link-slide">
              <a :href="item.link" target="_blank">
                <img class=""
                     :src="require('@/assets/img/further/artists/' + item.pic + '.jpg')"
                     alt="">
                <h3 class="main">{{item.name}}</h3>
                <span class="sub">{{item.kind}}</span>
              </a>
            </slide>
          </carousel>
          <div class="text-center title">
            <h1>Photographers</h1>
          </div>
          <carousel class="carousel artists-carousel" :perPageCustom="[[320, 1.2], [768, 2.5], [1440, 3]]"
                    :scrollPerPage="false">
            <slide v-for="item in artists.photographers" :key="item.index" class="link-slide">
              <a :href="item.link" target="_blank">
                <img class=""
                     :src="require('@/assets/img/further/artists/' + item.pic + '.jpg')"
                     alt="">
                <h3 class="main">{{item.name}}</h3>
                <span class="sub">{{item.kind}}</span>
              </a>
            </slide>
          </carousel>
          <div class="bottom-spacer"></div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { books, movies, artists } from '@/data';

export default {
  name: 'Further',

  data () {
    return {
      books: books,
      movies: movies,
      artists: artists,
    }
  },
  components: {},
  computed: {},
  methods: {},
  created () {

  }
}
</script>

<style lang="scss" scoped>
  main.further {
    padding-top: calc(60px + 5vw);
    padding-bottom: 10rem;

    .carousel {
      margin-bottom: 8rem;

      .VueCarousel-slide {
        padding: 1rem;
        box-sizing: border-box;
      }

      &.books-carousel {
        .book-desc {
          margin-top: 1.5rem;
          font-size: 1.4rem;
          line-height: 2rem;
        }
      }

      &.movies-carousel {

      }

      &.artists-carousel {
      }

      .link-slide {
        transition: 100ms ease-in-out;

        span {
          color: black;
        }

        &:hover {
          transform: translateY(-1rem);
        }
      }

      img {
        width: 100%;
        margin-bottom: 2rem;
      }

      h3.main {
        width: 100%;
        font-size: 1.7rem;
        line-height: 1.8rem;
        margin-bottom: 0;
        font-family: 'Couture', Helvetica, Arial, sans-serif;
      }

      sub {
        font-size: 1.2rem;
      }
    }
  }
</style>
